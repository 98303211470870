body {
	background-color: black;
	font-family: "Roboto", Sans-serif;
	font-weight: 400;
}

a.border.nav-link.border-light.rounded.mr-1 {
  color: #3F729B;
}

a.border.nav-link.border-light.rounded.mr-1:hover {
  color: #1C2331;
}

.btn.btn-sm.indigo:hover{
  color: #fff;
}

.nav-link.active{
  background-color: rgba(255, 255, 255, 0.1);
}

#categories > .col-md-4.mb-5{
  margin-bottom: 8rem !important;
}
.sectionPage {
  position: relative;
  top: -15px;
  margin-bottom: -125px;
}

table.table th, table.table td {
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
}

.table td, .table th {
  padding: 0.2rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.sectionPage::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  background: url('https://mdbootstrap.com/wp-content/uploads/2016/12/big-bundle1.jpg');
  background-position: center center;
  background-size: cover;
  filter: opacity(.1);
}

mgt-person {
  --avatar-size-s: 24px;
  --avatar-size: 48px; 
  --avatar-font-size--s: 16px;
  --avatar-font-size: 32px; 
  --avatar-border: 0;
  --initials-color: white;
  --initials-background-color: magenta;
  --font-size: 12px;
  --font-weight: 500;
  --color: black;
  --email-font-size: 12px;
  --email-color: black;
}

.note {
  margin: 2em 0 0 1em;
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto,
    'Helvetica Neue', sans-serif;
  color: #323130;
  font-size: 12px;
}

.cursorHover {
  cursor: pointer;
}

.fab {
  position: fixed;
  bottom: 65px;
  right: 10px;
}