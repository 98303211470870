body {
    color: #062a3e;
    font-size: 14px;
      
}

.jobStatuses {
    overflow: auto;
    white-space: nowrap;
}

.jobStatuses .card-row {
    display: inline-block;
    width: 300px;
    vertical-align: top;
}

.jobStatuses .card-title.sub{
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-subtitle {
    margin-top: 0.1rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: gray;
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hide {
    display: none;
  }
  
  .myDIV:hover + .hide {
    display: block;
    color: #e84651;
  }

  [class*="sidebar-dark-"] {
    background-color: #19a6b7;
  }

  .sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active {
    background-color: #e84651;
    color: #fff;
}

[class*=sidebar-dark-] .sidebar a {
    color: #062a3e;
}

.badge-danger {
    color: #fff;
    background-color: #e84651;
}

.bg-gradient-success {
    background: transparent !important;
    border: #73c769 solid 2px;
    color: #000;
}

.bg-success {
    background-color: #73c769!important;
}

.bg-gradient-danger {
    background: transparent !important;
    border: #e84651 solid 2px;
    color: #000;
}

.bg-gradient-warning {
    background: transparent !important;
    border: #ffc107 solid 2px;
    color: #000;
}

.bg-danger.btn:hover {
    border-color: #da1b28;
    color: #ececec;
}

.bg-danger {
    background-color: #e84651!important;
}

.bg-info {
    background-color: #19a6b7!important;
}

.fc-v-event {
    display: block;
    border: 1px solid #19a6b7;
    border: 1px solid #19a6b7;
    border: 1px solid var(--fc-event-border-color, #19a6b7);
    background-color: #19a6b7;
    background-color: #19a6b7;
    background-color: #19a6b7;
}

.fc-col-header-cell {
    color: #19a6b7 !important; 
}

.footer a {
    color: white; 
}

a {
    color: #19a6b7; 
}

.fc .fc-button-primary {
    color: #fff;
    color: #fff;
    color: var(--fc-button-text-color, #fff);
    background-color: #19a6b7 !important;
    background-color: #19a6b7 !important;
    background-color: #19a6b7 !important;
    border-color: #19a6b7 !important;
    border-color: #19a6b7 !important;
    border-color: var(--fc-button-border-color, #19a6b7);
}

.fc .fc-button-active {
    background-color: #117a8b !important;
}

.card-primary:not(.card-outline)>.card-header {
    background-color: #19a6b7;
}

[class*=sidebar-dark-] .sidebar a {
    color: white;
}

.brand-link .brand-image {
    float: left;
    line-height: .8;
    margin-left: 0.3rem;
    margin-right: 0.5rem;
    margin-top: -3px;
    max-height: 30px;
    width: auto;
}

[class*=sidebar-dark] .brand-link, [class*=sidebar-dark] .brand-link .pushmenu {
    color: rgb(255 255 255);
}

.btn-danger {
    color: #fff;
    background-color: #e84651;
    border-color: #e84651;
    box-shadow: none;
}

.btn-primary {
    color: #fff;
    background-color: #19a6b7;
    border-color: #19a6b7;
    box-shadow: none;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
    color: #fff;
    background-color: #117a8b;
    border-color: #117a8b;
}

[class*=sidebar-dark] .user-panel {
    border-bottom: 1px solid #c9c9c9;
}
[class*=sidebar-dark] .brand-link {
    border-bottom: 1px solid #c9c9c9;
}

.Mkanban {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    margin-left: 0;
}

.card.direct-chat.direct-chat-primary {
    margin-right: 10px;
}

.custom-switch.custom-switch-on-success .custom-control-input:checked~.custom-control-label::before {
    background-color: #0ccadf;
    border-color: #6f6f6f;
}

.custom-switch.custom-switch-on-success .custom-control-input:checked~.custom-control-label::after {
    background-color: #19a6b7;
    border-color: #6f6f6f;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #19a6b7;
}

.brand-text {
    color: white
}

.pac-container {
    z-index: 1100 !important;
  }