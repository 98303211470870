section {
    font-family: 'Gotham Rounded', sans-serif;        
}

/* ----------------------------------------------
 * Generated by Animista on 2022-8-7 13:37:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-right {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  }
  @keyframes slide-right {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  }

  
.slide-right {
	-webkit-animation: slide-right 3s linear infinite alternate backwards;
	        animation: slide-right 3s linear infinite alternate backwards;
}

.centre_modal{
    position:fixed;
    margin-left:auto;
    margin-right:auto;
    display:block;
    z-index:100;
 }